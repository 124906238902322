<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.joinRequestsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="joinRequestsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="joinRequests"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.joinRequests') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="700px">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>
                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(save)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols=" 12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="company_name">
                                  <v-text-field
                                    v-model="editedItem.company_name"
                                    name="company_name"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.companyName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>

                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="address">
                                  <v-text-field
                                    v-model="editedItem.address"
                                    name="address"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.address')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>

                              <v-col cols="12" lg="6" md="6" sm="12">
                                <gmap-autocomplete
                                  :options="googleMapsOptions"
                                  :setFieldsTo="GoogleApiFields"
                                  :value="editedItem.location_coordinates"
                                  selectFirstOnEnter
                                  @place_changed="selectLocationAddress">
                                  <template v-slot:default="slotProps">
                                    <validation-provider rules="required" v-slot="{ errors }" name="location">
                                      <v-text-field
                                        ref="input"
                                        v-model="editedItem.location_name"
                                        :label="$t('clientsPage.location')"
                                        @focusout="selectLocationAddressFocusout"
                                        v-on:attrs="slotProps.attrs"
                                        v-on:listeners="slotProps.listeners"
                                        :error-messages="errors"
                                        placeholder=""
                                        @keydown.enter.prevent
                                        >-
                                      </v-text-field>
                                    </validation-provider>
                                  </template>
                                </gmap-autocomplete>
                              </v-col>

                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  :rules="{
                                    max: 200,
                                    required: true,
                                    regex: /^\+?[0-9\s\-()]+$/,
                                  }"
                                  v-slot="{ errors }"
                                  name="phone_number">
                                  <v-text-field
                                    type="tel"
                                    name="phone"
                                    v-model="editedItem.phone"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.phoneNumber')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  rules="required|email|max:200"
                                  v-slot="{ errors }"
                                  name="email"
                                  ref="emailRef">
                                  <v-text-field
                                    type="email"
                                    name="email"
                                    v-model="editedItem.email"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.email')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="vat"
                                  ref="vatRef">
                                  <v-text-field
                                    v-model="editedItem.vat_number"
                                    name="vat"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.vat')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="pib"
                                  ref="vatRef">
                                  <v-text-field
                                    v-model="editedItem.pib"
                                    name="pib"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.pib')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider rules="required" v-slot="{ errors }" name="client_type">
                                  <v-autocomplete
                                    v-model="editedItem.client_type"
                                    :items="clientTypes"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('clientsPage.clientType')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="contact_person_name">
                                  <v-text-field
                                    v-model="editedItem.contact_person_name"
                                    name="contact_person_name"
                                    clearable
                                    counter="200"
                                    :label="$t('clientsPage.contactPersonName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider rules="required" v-slot="{ errors }" name="Organizacija">
                                  <v-autocomplete
                                      v-model="editedItem.organisation_id"
                                      :items="organisations"
                                      clearable
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('clientsPage.organisation')"
                                      :no-data-text="$t('select.noDataAvailable')"
                                      @focus="$event.target.click()"
                                      :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-content-save</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.accept') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.accept') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showClientModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
            {{ $t('snackbar.close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-dialog v-model="deleteClientModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('joinRequestsPage.deleteJoinRequest') }}
          <v-spacer></v-spacer>
          <v-icon class="mdi mdi-close" style="color: white" @click="closeJoinRequestModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('joinRequestsPage.deleteMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="deleteClient">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { joinRequestsHeaders } from '@/mixins/data-table-headers';
import { defaultClient } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'ClientsPage',
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    joinRequests: [],
    organisations: [],
    formTitle: '',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    enableSave: true,
    deleteClientModal: false,
    statusSuccessfullyChanged: false,
    loading: false,
    snackbar: {
      active: false,
      color: '',
      text: '',
    },
    googleMapsOptions: {
      bounds: {
        north: 45.830329,
        south: 41.51274,
        east: 20.834725,
        west: 16.332208,
      },
      strictBounds: false,
    },
    GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
    clientTypes: [],
  }),
  created() {
    this.editedItem = Object.assign({}, defaultClient);
    this.loadAllJoinRequests();
    this.loadAllClientTypes();
    this.loadOrganisations();
  },
  computed: {
    joinRequestsHeaders() {
      return joinRequestsHeaders(i18n);
    },
  },
  methods: {
    async loadAllJoinRequests() {
      await this.$store.dispatch('joinRequests/getAllJoinRequests').then((res) => {
        this.joinRequests = res.data.map((item) => {
          return { ...item, created_at: formatDate(item.created_at) };
        });
      });
    },

    async loadAllClientTypes() {
      await this.$store.dispatch('clients/getAllClientTypes').then((res) => {
        this.clientTypes = res.data.map((item) => {
          return { ...item, id: item, name: this.getClientTypeName(item) };
        });
      });
    },

    async loadOrganisations() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        this.organisations = res.data;
      });
    },

    editItem(item) {
      this.editedItem = this.joinRequests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = item.company_name;
      this.editedItem.oldEmail = item.email;

      this.editedItem.location_name = JSON.parse(item?.location_coordinates)?.formatted_address
        ? JSON.parse(item?.location_coordinates)?.formatted_address
        : '';

      this.editedItem.organisation_id = item.organisation?.id;

      this.dialog = true;
    },

    async deleteClient() {
      this.loading = true;

      await this.$store
        .dispatch('joinRequests/deleteJoinRequest', this.editedItemDeleted)
        .then(() => {
          this.loadAllJoinRequests();
          this.showSnackbar(i18n.t('snackbar.deletedSuccessfully'), 'green');
          this.closeJoinRequestModal();
        })
        .catch(() => {
          this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('_method', 'POST');

      formData.append('company_name', this.editedItem.company_name);
      formData.append('address', this.editedItem.address);
      formData.append('location_coordinates', JSON.stringify(this.editedItem.location_coordinates));
      formData.append('phone', this.editedItem.phone);
      formData.append('email', this.editedItem.email);
      formData.append('vat_number', this.editedItem.vat_number ? this.editedItem.vat_number : '');
      formData.append('pib', this.editedItem.pib ? this.editedItem.pib : '');
      formData.append('client_type', this.editedItem.client_type ? this.editedItem.client_type : '');
      formData.append('organisation_id', this.editedItem.organisation_id ? this.editedItem.organisation_id : '');
      formData.append(
        'contact_person_name',
        this.editedItem.contact_person_name ? this.editedItem.contact_person_name : ''
      );
      return formData;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveClient';

      let formData = this.createFormData();

      await this.$store
        .dispatch('clients/' + path, formData)
        .then(() => {
          this.loadAllJoinRequests();
          this.showSnackbar(i18n.t('snackbar.savedSuccessfully'), 'green');

          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response.data.errors?.pib) {
              if (error.response.data.errors?.pib && error.response.data.errors?.pib[0] === 'CLIENT-0018') {
                this.$refs.vatRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.pib'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.showSnackbar(e, 'red'));
            }
          } else {
            this.showSnackbar(i18n.t('snackbar.anErrorOccured'), 'red');
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultClient);
      });
      this.$refs.form.reset();
      this.formTitle = '';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultClient);
      });
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },

    showClientModal(item) {
      this.editedItemDeleted = item;
      this.deleteClientModal = true;
    },

    closeJoinRequestModal() {
      this.deleteClientModal = false;
    },

    selectLocationAddress(input) {
      this.editedItem.location_coordinates = input;
      this.editedItem.location_name = input.name + ` (${input.formatted_address})`;
    },

    selectLocationAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.location_coordinates?.name &&
        value !==
          this.editedItem.location_coordinates?.name + ` (${this.editedItem.location_coordinates?.formatted_address})`
      ) {
        this.editedItem.location_coordinates = { name: value };
        this.editedItem.location_name = value;
      }
    },

    getClientTypeName(type) {
      const clientTypeNames = {
        micro: 'clientTypes.micro',
        small: 'clientTypes.small',
        medium: 'clientTypes.medium',
        large: 'clientTypes.large',
      };

      return clientTypeNames[type] ? i18n.t(clientTypeNames[type]) : '';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style>
.stats {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
